import { useState } from "react"

/**
 * Hook qui gère le changement de valeur boolean.
 * @param {boolean} initialValue
 * @returns un tableau, valeurs du hook
 */
export function useToggle(initialValue){

  const [value,setValue] = useState(initialValue)

  const toogle = function(){
    setValue(val => !val)
  }
  return [value, toogle]
}


/**
 * Hook qui gère le changement de valeur suite à un évènement
 * @param {string} initialValue
 * @returns un tableau, valeur et fonction du hook
 */
export function useSearch(initialValue){

  const [value,setValue] = useState(initialValue)

  const updateValue = function(e){
    setValue(e.target.value)
  }

  return [value, updateValue]
}