//Import de librairies
import React, { useState } from "react"

//Import de fichiers
import menuFooter from '../../utils/Decorate/menuFooter.json'
import '../../scss/components/decorate/footer.scss'
import Logo from "../../images/logo_white.png"




export default function Footer() {

  const [ulOpened, setUlOpened] = useState("")
  return (
    <div className="footer">
      <svg style={{width:0,height:0,position:"absolute"}} aria-hidden="true" focusable="false">
        <linearGradient id="my-cool-gradient" >
          <stop offset="0%" stopColor="var(--color-gradOne)" />
          <stop offset="82%" stopColor="var(--color-gradTwo)" />
        </linearGradient>
      </svg>
      <div className="footer-top">
        <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 100">
            <path className="footer-bg"
                  d="M1920,100.13H0v-19.1c0,0,382.14-80,960-80s960,80,960,90V100.13z" />
        </svg>
      </div>
      <div className="footer-main">
        <div className="footer-rgt">
          <div className="client-grid">
            <div className="img-client">
              <img src={Logo} alt="" className="logo" />
            </div>
            <div className="img-client">
              <p>Qu’est-ce que GetCaaS? GetCaaS aide les entreprises et les professionnels de l'IT à déployer plus rapidement des applications, de façon contrôlée et automatisée. Tout cela fait avec passion depuis les Cévénnes et la Normandie <span role="contentinfo" aria-label="un petit coeur car c'est fait avec amour !" className="heart">❤️</span>️</p>
            </div>
          </div>
        <div className="stretched-link"></div>
        </div>
        <div className="footer-lft">
          {
            menuFooter.fr.map((ul) => {
              const key = Object.keys(ul)[0]
              return (
                <div key={key} className="footer-lft__col">
                  <span className="footer-lft__col__head">{key}</span>
                  <ul>
                    {
                      ul[key].map((li) => {

                        return (
                          (
                            li.state === "done" && <li key={li.link}><a href={li.link}>{li.title}</a></li>
                          )
                            ||
                          (
                            li.state === "incoming" &&
                            <li className="btn-connexion">
                              <span>{/*<AniLink to="" className="Link px-4">*/}{li.title}</span>
                            </li>
                          )
                        )
                      })
                    }
                  </ul>
                </div>
              )
            })
          }
        </div>
        <div className="footer-lft-responsive">
          {
            menuFooter.fr.map((ul) => {
              const key = Object.keys(ul)[0]
              return (
                <div key={key} className="footer-lft-responsive__col">
                  <span className="clickable footer-lft-responsive__col__head" aria-hidden="true" role="button" aria-label="changing_ul_opened" onClick={() => setUlOpened(ulOpened === key ? "" : key)}>{key}</span>
                  <ul  className={ulOpened === key ? "openedUl" : "closedUl"}>
                    {
                      ul[key].map((li) => {

                            return (
                              (
                                li.state === "done" && <li key={li.link}><a href={li.link}>{li.title}</a></li>
                              )
                              ||
                              (
                                li.state === "incoming" &&
                                <li className="btn-connexion">
                                  <span>{/*<AniLink to="" className="Link px-4">*/}{li.title}</span>
                                  <span className="incoming">En cours</span>
                                </li>
                              )
                            )
                        })
                    }
                  </ul>
                </div>
              )
            })
          }
        </div>

      </div>

    </div>
  )
}