import CookieConsent from 'react-cookie-consent';
import logo from "../../images/image001.png"
import React from "react"

import "../../scss/components/decorate/OwnCookie.scss"


function OwnCookie() {

  return (
      <CookieConsent

        disableStyles={true}
        enableDeclineButton
        location="none"
        containerClasses="mainContainerCookie"
        contentClasses="contentCookie"
        buttonWrapperClasses="btnWrapper"
        buttonClasses="btn btn-grad"
        declineButtonClasses="btn btn-grad"
        buttonText="J'accepte"
        declineButtonText="Je refuse"
        cookieName="gatsby-gdpr-google-analytics">
        <img src={logo} alt="image_indisponible_getcaas" style={{width:200, height:"auto"}} />
        <p>
          Pour GetCaas, votre expérience sur notre site est une priorité.
          C'est pourquoi nous utilisons des cookies et autres traceurs pour vous fournir notre service,
          le personnaliser, en mesurer l’audience et améliorer votre expérience.
        </p>
        <p>Sur la base de votre consentement des informations liées à votre navigation sur notre site sont stockées
          ou lues sur votre terminal (telle que l’IP, les pages visitées etc.) par GetCaas ou
          sur la base de l'intérêt légitime de ses partenaires afin de diffuser des publicités personnalisées,
          mesurer leurs performances, obtenir des données d'audience en lien avec cette publicité et développer
          et améliorer les produits.
        </p>
        <p>
          En cliquant sur “J'accepte”, vous consentez à l’utilisation de cookies pour l’ensemble des finalités ci-dessus.
          Vous pouvez également refuser en cliquant sur "Je refuse".
        </p>
      </CookieConsent>
  )

}

export default OwnCookie