/*Import de librairies*/
import React from "react"
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/*Import de fichiers en local*/
import Logo from "../../images/image001.png"
import "../../scss/components/decorate/Header.scss"
import { useToggle } from "../../utils/reusable/useHook"


function Navbar() {
  const [openedMenu, setOpenedMenu] = useToggle(false)

  return (
    <div className="header" id="myHeader">
      <div className="topnav">
        <a  href="/">
          <img className="logo img-responsive" src={Logo} alt="Logo" title="Logo" />
        </a>
        <div className="menu-item menu-item-not-responsive">
          <a className="Link" href="/why-getcaas">Pourquoi GetCaaS</a>
          <a className="Link" href="/how-it-works">Comment ça marche</a>


          <a className="btn-connexion" href="https://app.ongetcaas.io/auth/login">Se connecter</a>
          <a className="btn btn-grad" href="https://app.ongetcaas.io/auth/register">Créer un compte <span className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></span></a>

        </div>
        <div className={`menu-item menu-item-responsive ${openedMenu && "menu-item-responsive__isOpened"}`}>
          <a className="Link" href="/why-getcaas">Pourquoi GetCaaS</a>
          <a className="Link" href="/how-it-works">Comment ça marche</a>

          <a className="btn-connexion" href="https://app.ongetcaas.io/auth/login">Se connecter</a>
          <a className="btn btn-grad" href="https://app.ongetcaas.io/auth/register">Créer un compte <span className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></span></a>
        </div>
        <button className={`burger-menu clickable ${openedMenu && "burger-menu__isOpened"}`} onClick={setOpenedMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
    </div>
  )
}

export default Navbar